import React from "react";
import StoryPage from "../../components/story-page";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/marie-kl-young.jpg";
  const fullName = "Marie K.L. Young (Chow)";
  const zoomUrl = "https://us02web.zoom.us/j/88038787466";
  const zoomPhoneNumber = "(647) 374-4685";
  const zoomMeetingId = "880 3878 7466";
  const birthDate = "January 25th, 1938";
  const deathDate = "November 4th, 2020";
  const memorialDate = "Saturday November 21st, 2020";
  const memorialTime = "12:30pm ET";
  const inviteText =
    "Marie Young, entrepreneur and the former president of Young & Young Trading Co. Ltd., passed away Nov 4, 2020.  Please join us in remembering, celebrating and/or mourning her through a virtual funeral to be held Sat., Nov 21, at 12 pm.";
  const obituary = `Marie Young, age 82, died at home in Markham, Ontario on Nov. 4, 2020 after having lived a full life.  Smart and dynamic, she was the powerhouse behind Young & Young Trading Company Limited (Y&Y), a leading importer of Asian food in Canada.
  \nMarie is predeceased by devoted husband Victor and son Eugene; she is survived by loving partner Steve Young, sister Sau Cheuk Ma (Wing Fong), brother Joseph Chow (Claire), daughters Caroline (James Chao), Careen, Carmen (John Bifolchi) and Cara (Rick Tsang), grandchildren Joshua and Nathan Chao; Amelia, Jacob, and Juliet Bifolchi; and Justin and Erica Tsang.
  \nBorn and raised in Guangdong, China on January 25, 1938, Marie immigrated to Canada at just 20 years old, re-uniting with her parents, who were running a laundry mat in Cobourg Ontario.  After completing secretarial studies at Cobourg College, she moved to Toronto.  In 1960 she began working at Gan Young Fruit Market – owned and operated by Victor Young.  Victor had just begun importing Asian food through his company Young & Young Trading Co. In the following years, they married and started a family while Victor shifted his focus from the grocery store to the import business. A devoted mother, Marie went to help Victor in the family business only after the youngest child was in school.  Like other immigrants of the time, long hours and little pay were the hallmarks of their humble beginnings.
  \nMarie was an intrepid business leader.  She introduced Chinese food to the supermarkets (e.g. Loblaws) at a time when Asian food was sold in Asian stores only.  In the late 70’s, Marie brought computers to Y&Y.  Computers were not yet used in their field.  She set up systems to track inventory, orders and purchases and to produce a merchandise catalogue. The move revolutionized their business and positioned Y&Y to become a market leader. Marie routinely attended meetings of the Chinese Canadian Import & Export Association, and boldly took the helm as president for a time.  Later she traveled to Asia on her own to meet suppliers, attend food shows and return with new products. An entrepreneurial spirit, Marie left an indelible mark on not just Y & Y, but on her family and friends.`;
  const storiesRecordUrl = "https://stories.afterword.com/frd9ojdr8";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rfs76ea99cc9dpfk47s1y1apc2ibryh49mku9br0";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      birthDate={birthDate}
      deathDate={deathDate}
      customColumnSpacing="1fr 3fr"
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
    >
      <p>
        Donations in her memory can be made to the{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://support.tgwhf.ca/site/TR/PersonalFundraising/PersonalFundraising?px=1016078&pg=personal&fr_id=1061"
        >
          Toronto General Hospital Foundation
        </a>
        .
      </p>
    </StoryPage>
  );
};

export default ThisPage;
